/*eslint-disable*/
import React from "react";

import SectionAbout from "./Sections/SectionAbout";
import { PresentationPageWrapper } from "./styled";
import SectionLinks from "./Sections/SectionLinks";
import SectionFooter from "./Sections/SectionFooter";
import SectionKodo from "./Sections/SectionKodo";


export default function AboutPage() {
  return (
    <PresentationPageWrapper>
      <SectionLinks logo selected='/sobremi'/>
      <SectionAbout />
      <SectionKodo />
      <SectionFooter />
    </PresentationPageWrapper>
  );
}
